const $ = require('jquery');
// 
// create global $ and jQuery variables
global.$ = global.jQuery = $;

var L = require("leaflet")

// ADD THE CSS TO THE APP !!
// import 'leaflet/dist/leaflet.css';
// import './_leaflet.scss';

class LeafletMap {

    /**
     * @param {HTMLElement|null} element
     */
    constructor(element) {
        if (element === null) {
            return
        }

        this.leafletMap = null
        this.makerGroup = null
        this.markers = Array()
        this.lat = $(element).data('lat')
        this.lng = $(element).data('lng')
        this.iconConf = {
            iconUrl: '/images/icons/map_pin.png',
            iconSize: [30, 45],
            iconAnchor: [15, 45],
            popupAnchor: [-3, -76],
        }
        this.defaultCoords = [46.1390303,-2.4351915]

        this.initMap()

        if(this.lat && this.lng) {
            this.displayOnMap(this.returnFloat(this.lat), this.returnFloat(this.lng))
        }
    }

    returnFloat(val){
        return parseFloat(String(val).replace(',', '.'));site
    }

    initMap() {
        var coords = this.defaultCoords;
        var zoomLevel = 8;
        var myIcon = L.icon(this.iconConf);
        this.leafletMap = L.map('js-localisation-map').setView(coords, zoomLevel);
        // map.scrollWheelZoom.disable();
        var bounds = new L.latLngBounds();

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: ' '
        }).addTo(this.leafletMap);

        // Creating a Layer object
        var layer = new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
        this.leafletMap.fitBounds([
            this.defaultCoords
        ]);
    }

    // EVENT LOAD
    displayOnMap(lat, lng) {

        if(! (lng && lat) )
            return false
        
        lat = this.returnFloat(lat)
        lng = this.returnFloat(lng)
        // console.log(lng)
        // console.log(lat)
        if (this.markerGroup == null) {
            this.markerGroup = L.layerGroup().addTo(this.leafletMap);
        }
        this.markerGroup.clearLayers()

        var myIcon = L.icon(this.iconConf);
        var tmp = [lat, lng];
        var newMarker = new L.Marker(tmp, {
            icon: myIcon
        });

        newMarker.addTo(this.markerGroup);
        var bounds = new L.latLngBounds();
        this.leafletMap.fitBounds([
            [lat, lng]
        ]);
    }
}

// RUN
(function () {

    $(document).on('click', '.js-localisation-btn', function (e) {
        e.preventDefault();
        // window.displayOnMap();
    })

    var localisationSelector = '.js-map'
    window.MyMap = new LeafletMap(document.querySelector(localisationSelector))

})(jQuery)