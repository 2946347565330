// assets/app.js
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
// console.log('CMS FRONT 2');
// any CSS you import will output into a single css file (app.css in this case)
// import './styles/front/front_style.scss';

// Need jQuery? Install it with "yarn add jquery"(or "npm install jquery"), then uncomment to import it.
const $ = require('jquery');

// loads the Bootstrap jQuery plugins
import * as bootstrap from 'bootstrap'

// If you are using JavaScript/ECMAScript modules:
// import Dropzone from "dropzone";

window.$ = window.JQuery = $;

// AOS ANIMATION
import AOS from 'aos';
// import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

window.AOS = AOS;
// console.log(AOS)

/* FILTER */
import Filter from './js/front/Filter.js'
new Filter(document.querySelector('.js-filter'))

/* MAP */
import LeafletMap from './js/front/LeafletMap.js'

$(document).ready(function () {

    const resultModal = new bootstrap.Modal(document.getElementById('resultModal'), {
    })


    const ajaxModal = new bootstrap.Modal(document.getElementById('ajaxModal'), {
    })
    // var modal = bootstrap.Modal.getInstance('resultModal')

    // var tooltip = new bootstrap.Tooltip(exampleEl)

    // var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    // var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    // return new bootstrap.Tooltip(tooltipTriggerEl)
    // })

    // var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
    // var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
    // return new bootstrap.Dropdown(dropdownToggleEl)
    // })

    if ($('#carouselExampleCaptions').length) {
        var myCarousel = document.querySelector('#carouselExampleCaptions')
        var carousel = new bootstrap.Carousel(myCarousel, {
            interval: 6000,
        })

        myCarousel.addEventListener('slide.bs.carousel', function () {
            // alert('leave');
            $('.carousel-caption').fadeOut(200, function () {
                $('.carousel-caption').removeClass('aos-animate')
            })

        })

        myCarousel.addEventListener('slid.bs.carousel', function () {
            $('.carousel-caption').fadeIn(100)
            $('.carousel-caption').addClass('aos-animate')

        })

    }

    $(document).on('submit', 'form.ajax', function (e) {

        e.preventDefault();
        var form = $(this);
        var form_name = form.attr('name');
        var postUrl = form.attr('action');
        var button = $(form).find('button[type="submit"]');
        var buttonValue = button.text();
        button.text('...').prop('disabled', true);

        var formData = new FormData($(form)[0]);

        $.ajax({
            url: postUrl,
            type: "POST",
            data: formData,
            processData: false,
            contentType: false,

            success: function (response) {
                button.text(buttonValue);
                button.prop('disabled', false);
                console.log(response);

                // Erreur Reacptach, honeypot, ...
                if (response.error) {
                    resultModal.hide()
                    if (response.title) {
                        $('#resultModal .modal-title').html(response.title);
                    }
                    else {
                        $('#resultModal .modal-title').html('Une erreur est survenue');
                    }
                    $('#resultModal .modal-title').html();
                    $('#resultModal .modal-body').html(response.msg);
                    resultModal.show()
                }
                // Success
                else if (response.success) {
                    resultModal.hide()
                    if (response.title) {
                        $('#resultModal .modal-title').html(response.title);
                    }
                    else {
                        $('#resultModal .modal-title').html('Message envoyé');
                    }
                    $('#resultModal .modal-body').html(response.msg);
                    $(form).trigger("reset");
                    $(form).parent().html('<p class="text-center">Merci pour votre message, <br />Nous vous recontacterons dans les plus brefs délais.</p>');
                    resultModal.show()
                }
                else {
                    var newContent = $(response).find("[name='" + form_name + "'] .form-to-update"); // Récupère la portion à mettre à jour
                    var currentContent = $("[name='" + form_name + "'] .form-to-update"); // Récupère la portion à mettre à jour
                    if (newContent.length && currentContent.length) {
                        currentContent.html(newContent.html()); // Met à jour le contenu
                    }

                }

            }
        });
    });



});

$(window).on('scroll', function (event) {

    var scrollValue = $(window).scrollTop();

    var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth,
        y = w.innerHeight || e.clientHeight || g.clientHeight;

    //BACK TO THE TOP
    if ($('#back-to-the-top').length && x > 767) {
        if ($(this).scrollTop() >= 800) {
            $('#back-to-the-top').fadeIn(400);
        } else {
            $('#back-to-the-top').fadeOut(400);
        }
    }
    // -- BACK TO THE TOP

    // FIXED MENU
    var currentScroll = $(window).scrollTop(); // get current position

    if (currentScroll > 250) {
        $('#header.sticky-top').addClass('scrolled');
    } else {
        $('#header.sticky-top').removeClass('scrolled');
    }

});



