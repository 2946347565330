import {Flipper, spring} from 'flip-toolkit'

/**
 * @property {HTMLElement} pagination
 * @property {HTMLElement} content
 * @property {HTMLElement} sorting
 * @property {HTMLElement} basicFilter
 * @property {HTMLFormElement} form
 */
export default class Filter {

  /**
   * @param {HTMLElement|null} element
   */
  constructor(element) {
    // console.log('FILTER')
    if (element === null) {
      return
    }


    this.pagination = element.querySelector('.js-filter-pagination')
    this.content = element.querySelector('.js-filter-content')
    this.basicFilters = element.querySelector('.js-filter-basicFilters')
    this.sorting = element.querySelector('.js-filter-sorting')
    this.form = element.querySelector('.js-filter-form')
    this.bindEvents()
  }
  /**
   * Ajoute les comportements aux différents éléments
   */
  bindEvents() {
    const aClickListener = e => {
      if (e.target.tagName === 'A') {
        e.preventDefault()
        this.loadUrl(e.target.getAttribute('href'))
      }
    }
    if (this.sorting != null) {
      this.sorting.addEventListener('click', aClickListener)
    }
    if (this.basicFilters != null) {
      this.basicFilters.addEventListener('click', aClickListener)
    }
    if (this.pagination != null) {
      this.pagination.addEventListener('click', aClickListener)
    }
    if (this.form != null) {
      this.form.querySelectorAll('input').forEach(input => {
        input.addEventListener('change', this.loadForm.bind(this))
      })
    }

  }

  async loadForm() {
    const data = new FormData(this.form)
    const url = new URL(this.form.getAttribute('action') || window.location.href)
    const params = new URLSearchParams()
    data.forEach((value, key) => {
      params.append(key, value)
    })
    return this.loadUrl(url.pathname + '?' + params.toString())
  }

  async loadUrl(url, append = false) {
    this.showLoader()
    const params = new URLSearchParams(url.split('?')[1] || '')
    params.set('ajax', 1)
    const response = await fetch(url.split('?')[0] + '?' + params.toString(), {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    if (response.status >= 200 && response.status < 300) {
      const data = await response.json()
      // data.content 
      // this.sorting.innerHTML = data.sorting
      if (this.content != null) {
        // this.content.innerHTML = data.content
        this.flipContent(data.content, append)
      }
      if (this.basicFilters != null) {
        this.basicFilters.innerHTML = data.basicFilters
      }
      if (this.pagination != null) {
        this.pagination.innerHTML = data.pagination
      }

      // params contient l'url
      params.delete('ajax') // On ne veut pas que le paramètre "ajax" se retrouve dans l'URL
      history.replaceState({}, '', url.split('?')[0] + '?' + params.toString())

    } else {
      console.error(response)
    }
    this.hideLoader()
  }

  showLoader() {
    // Code à écrire
  }

  hideLoader() {
    // Code à écrire
  }

  /**
   * Remplace les éléments de la grille avec un effet d'animation flip
   * @param {string} content
   * @param {boolean} append le contenu doit être ajouté ou remplacé ?
   */
  flipContent(content, append) {
    const springConfig = 'gentle'
    const exitSpring = function (element, index, onComplete) {
      spring({
        config: 'stiff',
        values: {
          translateY: [0, -20],
          opacity: [1, 0]
        },
        onUpdate: ({
          translateY,
          opacity
        }) => {
          element.style.opacity = opacity;
          element.style.transform = `translateY(${translateY}px)`;
        },
        onComplete
      })
    }
    const appearSpring = function (element, index) {
      spring({
        config: 'stiff',
        values: {
          translateY: [20, 0],
          opacity: [0, 1]
        },
        onUpdate: ({
          translateY,
          opacity
        }) => {
          element.style.opacity = opacity;
          element.style.transform = `translateY(${translateY}px)`;
        },
        delay: index * 20
      })
    }
    const flipper = new Flipper({
      element: this.content
    })
    // Array.from(this.content.children).forEach(child => {
    //   console.log(child)
    // });
    Array.from(this.content.children).forEach(element => {
      console.log(element);
      flipper.addFlipped({
        element,
        spring: springConfig,
        flipId: element.id,
        shouldFlip: false,
        onExit: exitSpring
      })
    })
    flipper.recordBeforeUpdate()
    if (append) {
      this.content.innerHTML += content
    } else {
      this.content.innerHTML = content
    }
    Array.from(this.content.children).forEach(element => {
      flipper.addFlipped({
        element,
        spring: springConfig,
        flipId: element.id,
        onAppear: appearSpring
      })
    })
    flipper.update()
  }

}